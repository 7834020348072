import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../modules/NavBar";
import Footer from "../modules/Footer";
import ScrollToTop from "../components/ScrollToTop";

const MainLayout = () => {
  return (
    <>
      <ScrollToTop />
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default MainLayout;
