import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import Solutions from "../modules/Solutions";
import VisionMission from "../modules/VisionMission";
import OurStory from "../modules/OurStory";
import { Helmet } from "react-helmet";
import homeVideo from "../videos/meridio-hero.mp4";
import imgPosterHero from "../images/hero-poster.webp";
import OurCustomers from "../modules/OurCustomers";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          MeridioAI | Revolutionizing call-to-ordering with conversational AI
        </title>
        <meta
          name="description"
          content="MeridioAI leads the voice enabled food ordering industry revolution."
        />
        <meta
          property="og:title"
          content="MeridioAI | Revolutionizing call-to-ordering with conversational AI"
        />
        <meta
          property="og:description"
          content="MeridioAI leads the voice enabled food ordering industry revolution."
        />
      </Helmet>
      <Box
        sx={{
          overflow: "hidden",
        }}
      >
        <Container
          sx={{
            py: 3,
            display: "flex",
            alignItems: "center",
            position: "relative",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              component="video"
              autoPlay
              loop
              muted
              playsInline
              poster={imgPosterHero}
              sx={{
                height: "100%",
                width: "100%",
                borderRadius: "15px",
              }}
            >
              <source src={homeVideo} type="video/mp4" />
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h3"
              component="h1"
              sx={{ textAlign: "center", pt: { xs: 3, md: 0 } }}
            >
              Revolutionizing food ordering with conversational AI
            </Typography>
            <Typography variant="body" component="p" sx={{ py: 4 }}>
              MeridioAI handles every phone order so your staff no longer has to
              pick between answering the phone or serving an in-person customer.
            </Typography>
            <Link to="/contact">
              <Button
                variant="contained"
                sx={{ display: "inline-block" }}
                aria-label="Click to learn more"
              >
                Schedule a Demo
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>

      <Solutions />
      <VisionMission />
      <OurStory />
      <OurCustomers />
    </>
  );
};

export default Home;
