import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import imgMission from "../images/mission.gif";
import imgVision from "../images/vision.gif";

const VisionMission = () => {
  return (
    <>
      <Container
        sx={{
          pt: 3,
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Grid
          container
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={imgVision}
              alt="Our vision"
              sx={{
                width: "100%",
                maxWidth: 600,
                my: 2,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "none", md: 450 },
                pl: { xs: 0, md: 4 },
              }}
            >
              <Typography variant="h4" component="p" sx={{ mb: 2 }}>
                Our Vision:
              </Typography>
              <Typography variant="body" component="p">
                To be the leading provider of all conversational AI solutions
                for restaurants, transforming the call-to-ordering process and
                driving operational efficiency in the industry.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          pb: 3,
          display: "flex",
          alignItems: "center",
          position: "relative",
          height: { xs: "auto", md: 540 },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "none", md: 450 },
                pl: { xs: 0, md: 8 },
              }}
            >
              <Typography variant="h4" component="p" sx={{ mt: 5, mb: 2 }}>
                Our Mission:
              </Typography>
              <Typography variant="body" component="p">
                To deliver AI-driven solutions that empower restaurants to drive
                sales across all omni channels, build tremendous customer
                loyalty, and create a best-in-class customer service experience.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={imgMission}
              alt="Our mission"
              sx={{
                width: "100%",
                maxWidth: 600,
                my: 2,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default VisionMission;
