import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../modules/ContactForm";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
          Contact us | MeridioAI
        </title>
        <meta
          name="description"
          content="MeridioAI"
        />
        <meta
          property="og:title"
          content="Contact us | MeridioAI"
        />
        <meta
          property="og:description"
          content="MeridioAI"
        />
      </Helmet>
      <Box>
        <Box sx={{ overflowX: "hidden", maxWidth: "100%" }}>
          <Container maxWidth="lg" sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                mt: { xs: 0, lg: 10 },
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: { xs: "100%", lg: 450 },
                  maxWidth: { xs: "100%", lg: 450 },
                  display: { xs: "flex", lg: "block" },
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3" component="h1">
                  Contact us
                </Typography>
                <Typography
                  variant="body"
                  component="p"
                  sx={{ pr: { xs: 0, lg: 4 }, py: 4 }}
                >
                  Drop us a line and let us know how we can help take your business to the next level.
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 700,
                  px: { xs: 3, md: 7 },
                  py: { xs: 3, md: 5 },
                  mb: 5,
                }}
              >
                <ContactForm
                  showCompany
                  showDepartments
                  cta="Submit"
                  messageLabel="Tell us a little about you"
                  sx={{ maxWidth: 620 }}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Contact;
