import {
  Box,
  Button,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import emailjs from "@emailjs/browser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #DE340D",
  boxShadow: 24,
  p: 4,
};

const MeridioFormControl = styled(FormControl)(({ theme }) => ({
  margin: "14px 0",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    marginBottom: "20px",
  },
}));

const MeridioFormLabel = styled(Typography)(({ theme }) => ({
  width: 200,
  marginRight: "10px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginRight: 0,
    marginBottom: "10px",
    display: "block",
  },
}));

const MeridioFormText = styled(TextField)(({ theme }) => ({
  backgroundColor: "#ffffff",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const ContactForm = (props) => {
  const [name, setName] = useState("");
  const [business, setBusiness] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .send(
        "service_wzkjesk",
        "template_akxeq5h",
        {
          name: name,
          business: business,
          website: website,
          email: email,
          phone: phone,
          message: message,
        },
        "Wgraqm1sdrUdTBj9A"
      )
      .then(
        (result) => {
          handleOpenModal();
        },
        (error) => {
          console.log(error.text);
        }
      );
    handleClose();
  };

  const handleClose = () => {
    cleanForm();
  };

  const cleanForm = () => {
    setName("");
    setBusiness("");
    setWebsite("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  return (
    <Box sx={{ maxWidth: 840, flex: 1, ...props.sx }}>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <MeridioFormControl fullWidth>
          <MeridioFormLabel variant="formLabel">
            Name
            <Typography color="error.main" component="span">
              *
            </Typography>
          </MeridioFormLabel>
          <MeridioFormText
            required
            size="small"
            value={name}
            type="text"
            onInput={(e) => setName(e.target.value)}
          />
        </MeridioFormControl>
        <MeridioFormControl fullWidth>
        <MeridioFormLabel variant="formLabel">
            Business Name
            <Typography color="error.main" component="span">
            *
            </Typography>
        </MeridioFormLabel>
        <MeridioFormText
            required
            size="small"
            value={business}
            type="text"
            onInput={(e) => setBusiness(e.target.value)}
        />
        </MeridioFormControl>

        <MeridioFormControl fullWidth>
          <MeridioFormLabel variant="formLabel">
            Website URL
            <Typography color="error.main" component="span">
              *
            </Typography>
          </MeridioFormLabel>
          <MeridioFormText
            required
            size="small"
            value={website}
            type="text"
            onInput={(e) => setWebsite(e.target.value)}
          />
        </MeridioFormControl>

        <MeridioFormControl fullWidth>
          <MeridioFormLabel variant="formLabel">
            Email
            <Typography color="error.main" component="span">
              *
            </Typography>
          </MeridioFormLabel>
          <MeridioFormText
            required
            size="small"
            value={email}
            type="email"
            onInput={(e) => setEmail(e.target.value)}
          />
        </MeridioFormControl>

        <MeridioFormControl fullWidth>
          <MeridioFormLabel variant="formLabel">
            Phone
            <Typography color="error.main" component="span">
              *
            </Typography>
          </MeridioFormLabel>
          <MeridioFormText
            required
            size="small"
            value={phone}
            type="tel"
            onInput={(e) => setPhone(e.target.value)}
          />
        </MeridioFormControl>

        <FormControl
          fullWidth
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <MeridioFormLabel variant="formLabel">
            {props.messageLabel ? props.messageLabel : "How may we help?"}
            <Typography color="error.main" component="span">
              *
            </Typography>
          </MeridioFormLabel>
          <MeridioFormText
            required
            multiline
            size="small"
            value={message}
            type="text"
            rows={4}
            onInput={(e) => setMessage(e.target.value)}
          />
        </FormControl>
        <MeridioFormControl fullWidth>
          <MeridioFormLabel></MeridioFormLabel>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            sx={{ width: 210, mt: 2 }}
          >
            {props.cta}
          </Button>
        </MeridioFormControl>
      </form>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Thank you!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Thak you for taking the time to send us a message. A member of our
            team will reach out to you shortly if neccessary.
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default ContactForm;
