import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";

const About = () => {
  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h1" sx={{ mb: 3 }}>
        About Us
      </Typography>
      <Typography component="p" variant="bodySemi">
        We are MeridioAI - a conversational AI startup that helps restaurants
        grow their business. Based in Los Angeles, CA.
      </Typography>
      <Grid container sx={{ py: 4, display: "flex", alignItems: "center" }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <Box
            component="img"
            src="/images/santosh.webp"
            alt="Santosh Gumidelli"
            sx={{ width: "100%", maxWidth: 285, my: 3 }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography component="p" sx={{ pl: { xs: 0, md: 2 } }}>
            Our founder and CEO, Santosh Gumidelli, owned a restaurant for
            almost six years. With this in mind, he built our conversational AI
            to address only the most pertinent areas that are critical to a
            restaurant’s success: process orders and payments, deliver exquisite
            customer service, and build tremendous customer loyalty. Our
            proprietary AI will handle each focus area from beginning to end -
            which means that a restaurant’s staff will be able to fully focus on
            in-person customers. We will also stand out from competition by
            tailoring features specifically for the restaurant industry. We will
            prioritize accuracy, efficiency, and customer satisfaction, ensuring
            a seamless ordering experience that goes beyond simple chatbots.
          </Typography>
        </Grid>
      </Grid>
      <Typography component="p" variant="bodySemi">Key Business Objectives</Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item", ml: 2, p: 0 }}>
          Develop an advanced conversational AI system tailored specifically for
          the restaurant industry.
        </ListItem>
        <ListItem sx={{ display: "list-item", ml: 2, p: 0 }}>
          Partner with restaurants to integrate our AI technology into their
          ordering processes.
        </ListItem>
        <ListItem sx={{ display: "list-item", ml: 2, p: 0 }}>
          Scale our operations by expanding our customer base and enhancing our
          technology platform.
        </ListItem>
        <ListItem sx={{ display: "list-item", ml: 2, p: 0 }}>
          Provide exceptional customer service and support to ensure long-term
          success and customer satisfaction.
        </ListItem>
      </List>
    </Container>
  );
};

export default About;
