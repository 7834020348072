import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../logo.svg";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { navItems } from "../data/navItems";

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const [subNavEl, setSubNavEl] = React.useState(0);
  const handleSubNav = (index) => {
    setSubNavEl({ [index]: !subNavEl[index] });
  };

  //assigning location variable
  const location = useLocation();

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ height: "100px", position: "relative" }}>
          <Typography
            variant="bodySmall"
            component="p"
            sx={{ position: "absolute", top: { xs: 60, lg: 64 }, left: 56 }}
          >
            AI Powered Conversations
          </Typography>
          <Box
            sx={{ display: { display: "flex", xs: "none", lg: "flex" }, mr: 1 }}
          >
            <Link to="/" style={{ display: "flex", marginRight: "24px" }}>
              <img src={Logo} alt="MeridioAI logo" width="207" height="60" />
            </Link>
          </Box>

          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", lg: "none", alignItems: "center" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link to="/">
              <img src={Logo} alt="MeridioAI logo" width="207" height="60" />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="toggle menu"
              onClick={handleOpenNavMenu}
              color="inherit"
              disableRipple
              sx={{ px: 0 }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              id="menu-appbar"
              anchor="top"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <List sx={{ py: 0 }}>
                <ListItem
                  sx={{
                    py: 2.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Link to="/" onClick={handleCloseNavMenu}>
                    <img
                      src={Logo}
                      alt="RPM Moves logo"
                      width="207"
                      height="60"
                    />
                  </Link>
                  <IconButton
                    size="large"
                    disableRipple
                    onClick={handleCloseNavMenu}
                    variant="raised"
                    sx={{
                      minWidth: 0,
                      width: "30px",
                      pt: 1.75,
                      overflow: "hidden",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItem>
                {navItems.map((menu, index) => (
                  <Box key={index}>
                    <ListItem disablePadding>
                      <NavLink
                        to={menu.url}
                        style={{ textDecoration: "none", width: "100%" }}
                      >
                        <ListItemButton onClick={handleCloseNavMenu}>
                          <ListItemText
                            primary={menu.title}
                            primaryTypographyProps={{ fontSize: "1.25rem" }}
                          ></ListItemText>
                        </ListItemButton>
                      </NavLink>
                      {menu.submenu && (
                        <Button
                          sx={{ minWidth: 0 }}
                          onClick={() => handleSubNav(index)}
                          id={menu.id}
                        >
                          {subNavEl[index] ? (
                            <RemoveIcon fontSize="large" />
                          ) : (
                            <AddIcon fontSize="large" />
                          )}
                        </Button>
                      )}
                    </ListItem>
                    {menu.submenu && (
                      <Collapse
                        in={subNavEl[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {menu.submenu.map((submenu, idx) => (
                          <ListItem key={idx} sx={{ m: 0, p: 0 }}>
                            <NavLink to={submenu.url}>
                              <ListItemButton onClick={handleCloseNavMenu}>
                                <ListItemText
                                  primary={submenu.title}
                                  primaryTypographyProps={{
                                    fontSize: "1.5rem",
                                    paddingLeft: 3,
                                  }}
                                ></ListItemText>
                              </ListItemButton>
                            </NavLink>
                          </ListItem>
                        ))}
                      </Collapse>
                    )}
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 4,
                  }}
                >
                  <Link to="/contact" style={{ textDecoration: "none" }}>
                    <Button
                      variant="contained"
                      aria-label="Click to contact us"
                      disableElevation
                      onClick={handleCloseNavMenu}
                      sx={{ height: 45, px: { xs: 2, lg: 3 }, fontWeight: 500 }}
                    >
                      Contact us
                    </Button>
                  </Link>
                </Box>
              </List>
            </Drawer>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                lg: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              },
            }}
          >
            {navItems.map((menu, index) => (
              <Box
                key={index}
                sx={{ mx: { xs: 0, lg: 2 }, position: "relative" }}
                className={
                  location === menu.slug ? "top-nav active" : "top-nav"
                }
              >
                <Link
                  to={menu.url}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 16px",
                  }}
                >
                  <Typography variant="navLink">{menu.title}</Typography>
                  {menu.submenu && (
                    <>
                      <ExpandMoreRoundedIcon className="show-more"></ExpandMoreRoundedIcon>
                    </>
                  )}
                </Link>
                {menu.submenu && (
                  <List
                    sx={{
                      position: "absolute",
                      mx: 0,
                      p: 0,
                    }}
                  >
                    {menu.submenu.map((submenu, idx) => (
                      <ListItem key={idx} sx={{ m: 0, p: 0 }}>
                        <Link
                          to={submenu.url}
                          style={{
                            textDecoration: "none",
                            padding: "9px 30px",
                            width: "100%",
                          }}
                        >
                          <Typography
                            variant="subNavLink"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {submenu.title}
                          </Typography>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            ))}
            <Box
              sx={{
                mx: { xs: 0, lg: 3 },
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <NavLink to="/contact" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  aria-label="Click to contact us"
                  disableElevation
                  sx={{ height: 45, px: { xs: 2, lg: 3 }, fontWeight: 500 }}
                >
                  Contact us
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
