import { List, ListItem, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";

import imgSolutions from "../images/solution.gif";

const items = [
  "Process Orders without customers waiting in a queue",
  "Securely handle payments",
  "Provide AI-driven item recommendations for new and existing customers",
  "Handle FAQs",
  "Manage reservations",
  "End-to-end marketing using Google Adwords, Facebook and Instagram",
  "Deliver analytics and reporting on customer selections, popular dishes, and order trends",
];

const Solutions = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f9fafa",
        overflow: "hidden",
        py: 10,
        my: 3,
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "flex-start", md: "flex-end" },
          position: "relative",
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            maxWidth: { xs: "none", md: 550 },
          }}
        >
          <Typography variant="h4" component="p" sx={{ mb: 3 }}>
            Our Solution to an Age-Old Problem
          </Typography>
          <Typography variant="body" component="p">
            MeridioAI is revolutionizing how restaurants engage with customers
            over the phone:
          </Typography>
          <List sx={{ listStyleType: "disc" }}>
            {items.map((item, i) => (
              <ListItem
                sx={{ display: "list-item", p: 0, ml: 3, py: 1, width: "90%" }}
                key={i}
              >
                <Typography variant="bodySemi">{item}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={imgSolutions}
            alt="restaurant server"
            sx={{
              width: "100%",
              maxWidth: 412,
              borderRadius: "12px",
              top: 0,
              marginTop: { xs: 4, md: 0 },
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Solutions;
